.carousel {
  width: 100%;
}

.carousel .navButton svg {
  fill: 'blue';
}
.carousel .indicatorContainer svg {
  height: 6px;
  width: 6px;
}
